import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/layouts/layout/layout"
import MDXBody from "../components/layouts/MDXbody/mdx-body"
import BlogHero from "../components/layouts/blog/blog-hero"

export default function PageTemplate(props: any) {
  const { mdx } = props.data // data.mdx holds our post data
  const { frontmatter: fn, body, timeToRead } = mdx

  return (
    <Layout>
      <BlogHero
        title={fn.title}
        author={fn.author}
        date={fn.lastUpdatedAt && `Last updated at ${fn.lastUpdatedAt}`}
        timeToRead={timeToRead}
      />
      <MDXBody padded={false}>
        <MDXRenderer>{body}</MDXRenderer>
      </MDXBody>
    </Layout>
  )
}

export const Head = ({
  data,
}: {
  data: {
    mdx: {
      frontmatter: {
        metaTitle?: string
        metaDescription?: string
        title: string
      }
    }
  }
}) => {
  const { metaTitle, metaDescription, title } = data.mdx.frontmatter

  return (
    <>
      <title>{metaTitle || title}</title>
      <meta name="description" content={metaDescription || ""} />
      <meta property="og:title" content={metaTitle || title} />
      <meta property="og:description" content={metaDescription || ""} />
      <meta property="og:type" content="website" />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:author" content="ORY" />
      <meta property="twitter:title" content={metaTitle || title} />
      <meta property="twitter:description" content={metaDescription || ""} />
    </>
  )
}

export const pageQuery = graphql`
  query ($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      timeToRead
      frontmatter {
        path
        title
        metaTitle
        metaDescription
        lastUpdatedAt
        author
      }
    }
  }
`
